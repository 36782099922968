import { EXTERNAL_LINK } from '../../../constants/common';
import { routes } from '../../../constants/routes';
import { isProduction } from '../../../utils/miscUtils';

const values = {
  cdnPrefix: 'https://us.wink.co.kr/marketing/homepage/Main/PC/v4',
  top: {
    bgUrl: 'https://s.wink.co.kr/marketing/homepage/Main/PC/v3/bg_main_top.png',
    bgLink: 'https://bit.ly/412SAAl',
  },
  mainContent: {
    mainContentValues: {
      imgCount: 39,
      prefix:
        'https://s.wink.co.kr/marketing/homepage/Main/PC/v3/main_content_1920/main_content_1920',
      modifiedInfoList: [
        {
          modifiedIndexList: [
            1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
            21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
            38, 39,
          ],
          modifiedDate: '240403',
        },
        {
          modifiedIndexList: [5],
          modifiedDate: '240327',
        },
        {
          modifiedIndexList: [
            25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
          ],
          modifiedDate: '240117',
        },
        {
          modifiedIndexList: [25, 26],
          modifiedDate: '231220',
        },
      ],
      customStyleList: {
        2: { height: 407 },
      },
    },
    mainContentBgValues: {
      imgCount: 41,
      prefix:
        'https://s.wink.co.kr/marketing/homepage/Main/PC/v3/main_content_2560/main_content_2560',
      modifiedInfoList: [
        {
          modifiedIndexList: [1, 2, 7, 8, 25, 26, 28, 32, 36, 37, 38, 39],
          modifiedDate: '240403',
        },
        {
          modifiedIndexList: [26, 28, 30, 32, 36, 37, 38, 39, 40],
          modifiedDate: '240117',
        },
      ],
    },
    linkList: [
      {
        index: 3,
        linkUrl: routes.wink.freeStudy,
      },
      {
        index: 5,
        linkUrl: routes.wink.freeStudy,
      },
      {
        index: 12,
        linkUrl: routes.wink.freeStudy,
      },
    ],
    swiperValues: [
      {
        key: 'swiper-2',
        index: 37,
        count: 10,
        width: 735,
        prefix:
          'https://us.wink.co.kr/marketing/homepage/Main/PC/v3/image/main_content_37',
        modifiedInfoList: [],
        type: 'normal',
        swipeParams: {
          spaceBetween: 0,
        },
        navigatorImg: {
          prev: 'https://us.wink.co.kr/marketing/homepage/Main/PC/v3/image/main_content_36_left.png',
          next: 'https://us.wink.co.kr/marketing/homepage/Main/PC/v3/image/main_content_36_right.png',
        },
      },
      {
        key: 'swiper-3',
        index: 25,
        count: 3,
        width: 180,
        prefix:
          'https://us.wink.co.kr/marketing/homepage/Main/PC/v3/image/machine_bot',
        modifiedInfoList: [],
        type: 'bot',
        swipeParams: {
          spaceBetween: 0,
          slidesPerView: 1,
          centeredSlides: true,
        },
        navigatorImg: {
          prev: 'https://us.wink.co.kr/marketing/homepage/Main/PC/v3/image/machine_arrow_left.png',
          next: 'https://us.wink.co.kr/marketing/homepage/Main/PC/v3/image/machine_arrow_right.png',
        },
      },
      {
        key: 'swiper-4',
        index: 26,
        count: 2,
        width: 180,
        prefix:
          'https://us.wink.co.kr/marketing/homepage/Main/PC/v3/image/machine_school',
        modifiedInfoList: [],
        type: 'school',
        swipeParams: {
          spaceBetween: 0,
          slidesPerView: 1,
          centeredSlides: true,
        },
        navigatorImg: {
          prev: 'https://us.wink.co.kr/marketing/homepage/Main/PC/v3/image/machine_arrow_left.png',
          next: 'https://us.wink.co.kr/marketing/homepage/Main/PC/v3/image/machine_arrow_right.png',
        },
      },
    ],
    videoList: [
      {
        index: 2,
        order: 0,
        url: 'https://s.wink.co.kr/marketing/homepage/Main/PC/v2/video/main_top_video_230419.mp4',
        thumbnail:
          'https://s.wink.co.kr/marketing/homepage/Main/PC/v2/video/main_top_video_thumbnail_230419.png',
        className: 'real-story',
        autoPlay: true,
      },
      {
        index: 25,
        order: 1,
        url: 'https://s.wink.co.kr/marketing/homepage/Main/PC/v3/video/main_content_29_1.mp4',
        thumbnail:
          'https://s.wink.co.kr/marketing/homepage/Main/PC/v2/video/video3_thumbnail.png',
        pauseButtonUrl: 'https://s.wink.co.kr/pc/renewal/pause_button.png',
        playButtonUrl: 'https://s.wink.co.kr/pc/renewalV3/main/play_button.png',
        className: 'introduce-device',
      },
      {
        index: 26,
        order: 2,
        url: 'https://s.wink.co.kr/marketing/homepage/Main/PC/v3/video/main_content_29_2.mp4',
        thumbnail:
          'https://s.wink.co.kr/marketing/homepage/Main/PC/v2/video/video2_thumbnail.png',
        pauseButtonUrl: 'https://s.wink.co.kr/pc/renewal/pause_button.png',
        playButtonUrl: 'https://s.wink.co.kr/pc/renewalV3/main/play_button.png',
        className: 'school',
      },
    ],
    aniImgValues: [
      {
        index: 4,
        url: 'https://s.wink.co.kr/marketing/homepage/Main/PC/v3/gif/main_content_5_1_240327.gif',
        style: {
          top: '68.3%',
          left: '17.4%',
        },
        imgStyle: {
          width: '162px',
          height: '90px',
          border: '6px solid #3e3e3e',
          borderRadius: '7px',
        },
      },
      {
        index: 7,
        url: 'https://s.wink.co.kr/marketing/homepage/Main/PC/v3/gif/main_content_10.gif',
        style: {
          top: '22%',
          left: '50%',
          transform: 'translateX(-50%)',
          padding: 0,
          borderRadius: '30px',
          border: '7px solid #e8e8e8',
          boxShadow: '0 4px 0 #D9D9D9',
        },
        imgStyle: {
          borderRadius: '20px',
        },
      },
      {
        index: 15,
        url: 'https://s.wink.co.kr/marketing/homepage/Main/PC/v3/gif/main_content_17_1.gif',
        style: {
          top: '34.5%',
          left: '26.6%',
          maxWidth: '418px',
          maxHeight: '220px',
          width: '22%',
        },
      },
      {
        index: 15,
        url: 'https://s.wink.co.kr/marketing/homepage/Main/PC/v3/gif/main_content_17_2.gif',
        style: {
          top: '34%',
          left: '53%',
          maxWidth: '418px',
          maxHeight: '220px',
          width: '22%',
        },
      },
      {
        index: 17,
        url: 'https://s.wink.co.kr/marketing/homepage/Main/PC/v3/gif/main_content_19.gif',
        style: {
          top: '60%',
          left: '25.2%',
          maxWidth: '998px',
          maxHeight: '563px',
          width: '52%',
        },
        imgStyle: {
          borderRadius: '18px',
        },
      },
      {
        index: 32,
        url: 'https://s.wink.co.kr/marketing/homepage/Main/PC/v3/gif/main_content_26_1.gif',
        style: {
          top: '51%',
          left: '24.6%',
          width: '310px',
          height: '176px',
          border: ' 5px solid white',
          borderRadius: '20px',
          boxShadow: '0 4px 0 #E5E5E5',
        },
        imgStyle: {
          borderRadius: '15px',
          width: '310px',
          height: '176px',
          objectFit: 'cover',
        },
      },
      {
        index: 32,
        url: 'https://s.wink.co.kr/marketing/homepage/Main/PC/v3/gif/main_content_26_2.gif',
        style: {
          top: '51%',
          left: '51.4%',
          width: '310px',
          height: '176px',
          border: ' 5px solid white',
          borderRadius: '20px',
          boxShadow: '0 4px 0 #E5E5E5',
        },
        imgStyle: {
          borderRadius: '15px',
          width: '310px',
          height: '176px',
          objectFit: 'cover',
        },
      },
      {
        index: 32,
        url: 'https://s.wink.co.kr/marketing/homepage/Main/PC/v3/gif/main_content_26_3.gif',
        style: {
          top: '72.3%',
          left: '51.4%',
          width: '310px',
          height: '176px',
          border: ' 5px solid white',
          borderRadius: '20px',
          boxShadow: '0 4px 0 #E5E5E5',
        },
        imgStyle: {
          borderRadius: '15px',
          width: '310px',
          height: '176px',
          objectFit: 'cover',
        },
      },
      {
        index: 32,
        url: 'https://s.wink.co.kr/marketing/homepage/Main/PC/v3/gif/main_content_26_4.gif',
        style: {
          top: '72.3%',
          left: '24.6%',
          width: '310px',
          height: '176px',
          border: ' 5px solid white',
          borderRadius: '20px',
          boxShadow: '0 4px 0 #E5E5E5',
        },
        imgStyle: {
          borderRadius: '15px',
          width: '310px',
          height: '176px',
          objectFit: 'cover',
        },
      },
      {
        index: 30,
        url: 'https://s.wink.co.kr/marketing/homepage/Main/PC/v3/gif/main_content_28_1.gif',
        style: {
          top: '40.8%',
          left: '13.85%',
          maxWidth: '431px',
          maxHeight: '263px',
          width: '22.65%',
          aspectRatio: '431 / 245',
        },
        imgStyle: {
          borderRadius: '15px',
          height: '100%',
        },
      },
      {
        index: 30,
        url: 'https://s.wink.co.kr/marketing/homepage/Main/PC/v3/gif/main_content_28_2.gif',
        style: {
          top: '40.8%',
          left: '38.8%',
          maxWidth: '431px',
          maxHeight: '263px',
          width: '22.65%',
          aspectRatio: '431 / 245',
        },
        imgStyle: {
          borderRadius: '15px',
          height: '100%',
        },
      },
      {
        index: 30,
        url: 'https://s.wink.co.kr/marketing/homepage/Main/PC/v3/gif/main_content_28_3.gif',
        style: {
          top: '40.8%',
          left: '63.65%',
          maxWidth: '431px',
          maxHeight: '263px',
          width: '22.65%',
          aspectRatio: '431 / 245',
        },
        imgStyle: {
          borderRadius: '15px',
          height: '100%',
        },
      },
      {
        index: 28,
        url: 'https://s.wink.co.kr/marketing/homepage/Main/PC/v3/gif/main_content_32_1.gif',
        style: {
          top: '47.6%',
          left: '24.5%',
          maxWidth: '480px',
          maxHeight: '276px',
          width: '24.7%',
          aspectRatio: '480 / 271',
        },
        imgStyle: {
          borderRadius: '15px',
          height: '100%',
        },
      },
      {
        index: 28,
        url: 'https://s.wink.co.kr/marketing/homepage/Main/PC/v3/gif/main_content_32_2.gif',
        style: {
          top: '47.6%',
          left: '50.9%',
          maxWidth: '480px',
          maxHeight: '276px',
          width: '24.7%',
          aspectRatio: '480 / 271',
        },
        imgStyle: {
          borderRadius: '15px',
          height: '100%',
        },
      },
      {
        index: 34,
        url: 'https://s.wink.co.kr/marketing/homepage/Main/PC/v3/gif/main_content_34.gif',
        style: {
          top: '42.8%',
          left: '45.25%',
          maxWidth: '561px',
          maxHeight: '322px',
          width: '29%',
          aspectRatio: '561 / 322',
        },
        imgStyle: {
          borderRadius: '15px',
          height: '100%',
        },
      },
      {
        index: 34,
        url: 'https://s.wink.co.kr/marketing/homepage/Main/PC/v3/image/main_content_34.png',
        style: {
          top: '55.5%',
          left: '65.5%',
          width: '14%',
          aspectRatio: '243 / 215',
        },
      },
    ],
    buttonValues: [],
  },
  mainContentColorsUrl: `https://s.wink.co.kr/marketing/homepage/Main/PC/v3/main_content_colors${
    isProduction() ? '' : '_staging'
  }.json`,
  mainTopBannerColorsUrl:
    'https://s.wink.co.kr/marketing/homepage/Main/PC/v3/main_top_banner_colors.json',
  bgColorDefault: '#f6f6f6',
  rightFloating: {
    closeButton:
      'https://s.wink.co.kr/pc/renewalV3/floating_right_banner/close_221019.png',
    openButton:
      'https://s.wink.co.kr/pc/renewalV3/floating_right_banner/open_221019.png',
    freeStudyRed:
      'https://s.wink.co.kr/pc/renewalV3/floating_right_banner/free_study_r.png',
    freeStudyBlue:
      'https://s.wink.co.kr/pc/renewalV3/floating_right_banner/free_study_b.png',
    freeStudyBlink:
      'https://s.wink.co.kr/pc/renewalV3/floating_right_banner/free_study_blink_230419_v2.png',
    benefit:
      'https://s.wink.co.kr/pc/renewalV3/floating_right_banner/benefit_231227.png',
    csCenter:
      'https://s.wink.co.kr/pc/renewalV3/floating_right_banner/cs_center_221019.png',
    referenceRoom:
      'https://s.wink.co.kr/marketing/homepage/floating/reference-room-240424.png',
    referenceRoomButton:
      'https://s.wink.co.kr/marketing/homepage/floating/reference-room-btn-240424.png',
    donation: 'https://s.wink.co.kr/marketing/homepage/floating/donation.png',
    donationRequest:
      'https://s.wink.co.kr/marketing/homepage/floating/donation-request.png',
    groupRequest: 'https://forms.gle/DvSKmcM9D45dYq7VA',
    groupRequestButton:
      'https://s.wink.co.kr/marketing/homepage/floating/group-request-btn.png',
  },
  rightFloatingText: {
    downloadAppText: '학부모 앱 다운로드',
  },
  rightFloatingMenu: [
    {
      id: 'floating-download-parent-app',
      className: 'lay-download-parent-app',
      path: routes.customer.download,
      text: '학부모 앱 다운로드',
      iconSrc: 'https://s.wink.co.kr/pc/renewalV3/icons/app_download.svg',
    },
    {
      id: 'floating-download-recruit-teacher',
      className: 'lay-recruit-teacher',
      path: routes.customer.teacher,
      text: '윙크 선생님 모집',
      iconSrc: 'https://s.wink.co.kr/pc/renewalV3/icons/recruit_teacher.svg',
    },
    {
      id: 'floating-download-promotion-teacher',
      className: 'lay-recruit-promotion-teacher',
      path: routes.customer.promotionTeacher,
      text: '홍보 상담 선생님 모집',
      iconSrc: 'https://s.wink.co.kr/pc/renewalV3/icons/recruit_teacher.svg',
    },
    {
      id: 'floating-happy-talk',
      className: 'lay-happy-talk',
      text: '1:1 실시간 채팅 상담',
      iconSrc: 'https://s.wink.co.kr/pc/renewalV3/icons/chatting.svg',
      path: 'https://customer.happytalk.io/public_v1/chat_v4/public_point?go=C&is_login=N&uid=&site_id=4000000177&category_id=64405&division_id=64406&usergb=W&title=%5B%ED%85%8C%EC%8A%A4%ED%8A%B8+%EC%83%81%EB%8B%B4%EC%B0%BD%5D',
      externalProps: {
        target: 'happytalk',
        features:
          'width=400, height=600, menubar=no, status=no, toolbar=no, location=no, scrollbars=no',
      },
    },
    {
      id: 'floating-group-consultation',
      className: 'lay-group-consultation',
      path: EXTERNAL_LINK.GROUP_CONSULTATION,
      text: '단체(어린이집/유치원 등)\n해외 맞춤 상담',
      iconSrc: 'https://s.wink.co.kr/pc/renewalV3/icons/new_square.svg',
      externalProps: {
        target: '_blank',
      },
    },
  ],
  middle: {
    topUrl: 'https://s.wink.co.kr/pc/renewal/main/top_img.png',
    bottomUrl: 'https://s.wink.co.kr/pc/renewal/main/bottom_img.png',
  },
  video: {
    description: 'https://s.wink.co.kr/pc/renewal/main_video_description.png',
    playButtonUrl: 'https://s.wink.co.kr/pc/renewal/play_button.png',
    pauseButtonUrl: 'https://s.wink.co.kr/pc/renewal/pause_button.png',
    totalCount: '5',
    list: [
      {
        videoUrl: 'https://s.wink.co.kr/pc/renewal/main_first_video.mp4',
        posterUrl:
          'https://s.wink.co.kr/pc/renewal/main_first_video_thumbnail.png',
        text: ['영어책을 읽고 해석까지 척척하는 7살 다인이'],
      },
      {
        videoUrl: 'https://s.wink.co.kr/pc/renewal/main_second_video.mp4',
        posterUrl:
          'https://s.wink.co.kr/pc/renewal/main_second_video_thumbnail.png',
        text: ['엄마에게 오히려 글자를 가르쳐주는 4살 하은이'],
      },
      {
        videoUrl: 'https://s.wink.co.kr/pc/renewal/main_third_video.mp4',
        posterUrl:
          'https://s.wink.co.kr/pc/renewal/main_third_video_thumbnail.png',
        text: ['친구 같은 윙크 영어로 신나게 공부하는 6살 찬호'],
      },
      {
        videoUrl: 'https://s.wink.co.kr/pc/renewal/main_fourth_video.mp4',
        posterUrl:
          'https://s.wink.co.kr/pc/renewal/main_fourth_video_thumbnail.png',
        text: ['수학 원리를 완벽히 이해하고', '설명까지 하는 8살 승원이'],
      },
    ],
    bottom: {
      videoUrl: 'https://s.wink.co.kr/pc/renewal/main_content_second_video.mp4',
      posterUrl:
        'https://s.wink.co.kr/pc/renewal/main_content_second_thumbnail.png',
    },
  },
  bottomBtnUrl: 'https://s.wink.co.kr/pc/renewal/marketing/bn.png',
};

export default values;
