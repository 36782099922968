import moment from 'moment';

export const baseName = '/client/desktop';

export const DATE_FLAG = moment().isSameOrAfter('2019-02-18');

export const format = {
  FULL_DATETIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  DATETIME_FORMAT: 'YYYY-MM-DD HH:mm',
  DATE_FORMAT: 'YYYY-MM-DD',
  TIME_FORMAT: 'HH:mm',
  TIME_FORMAT_SEC: 'HH:mm:ss',
  DAY_FORMAT: 'ddd',
};

export const boardName = {
  review: '사용자후기',
};

export const boardType = {
  PUBLIC_BOARD: 'PublicBoard',
};

export const BANK_CODE = {
  '03': '기업',
  '06': '국민',
  '07': '수협',
  11: '농협',
  20: '우리',
  26: '신한',
  31: '대구',
  32: '부산',
  39: '경남',
  71: '우체국',
  81: 'KEB하나',
};

export const requestCompleteButtonIcons = {
  kakao: 'https://s.wink.co.kr/pc/images/share_free_study_kakao.png',
  copy: 'https://s.wink.co.kr/pc/images/share_free_study_link.png',
};

export const requestCompleteMessage = {
  kakao: {
    objectType: 'text',
    text: '4~9세 학부모님들이 가장 많이 선택하는 검증된 학습법, 윙크!\n지금 지인 학부모님과 함께 무료체험하시면 선물을 드려요!\n[무료체험 간편신청] https://vo.la/Af6vp',
    link: {
      mobileWebUrl:
        'https://m.wink.co.kr/client/mobile/acquaintance/?teacher=8596974',
    },
  },
  copy: '4~9세 학부모님들이 가장 많이 선택하는 검증된 학습법, 윙크!\n지금 지인 학부모님과 함께 무료체험하시면 선물을 드려요!\n[무료체험 간편신청] https://vo.la/vjh2b',
};

export const AgreementSrc = {
  //윙크 이용 약관
  winkAgree: 'https://s.wink.co.kr/danbi_common/html/agreement.html',
  //정책
  privacyPolicy:
    'https://s.wink.co.kr/danbi_common/html/privacy_policy_20231129.html',
  //마케팅
  marketingPolicy:
    'https://s.wink.co.kr/danbi_common/html/marketing_policy.html',
  //광고
  advertisingPolicy:
    'https://s.wink.co.kr/danbi_common/html/advertising_policy.html',
};

export const defaultServiceTerms = [
  {
    accordionContext: {
      label: '윙크(wink) 이용약관 동의 (필수)',
      iframeSrc: AgreementSrc.winkAgree,
    },
    checkboxContext: {
      id: 'agree-check01',
      value: false,
    },
  },
  {
    accordionContext: {
      label: '개인정보수집 및 활용 동의 (필수)',
      iframeSrc: AgreementSrc.privacyPolicy,
    },
    checkboxContext: {
      id: 'agree-check02',
      value: false,
    },
  },
  {
    accordionContext: {
      label: '마케팅 목적 활용 동의 (선택)',
      iframeSrc: AgreementSrc.marketingPolicy,
    },
    checkboxContext: {
      id: 'agree-check03',
      value: false,
    },
  },
  {
    accordionContext: {
      label: '광고성 정보 수신 동의 (선택)',
      iframeSrc: AgreementSrc.advertisingPolicy,
    },
    checkboxContext: {
      id: 'agree-check04',
      value: false,
    },
  },
  {
    accordionContext: {
      label: '만 14세 이상입니다. (필수)',
    },
    checkboxContext: {
      id: 'agree-check05',
      value: false,
    },
  },
];

export const winkBooksUrl = {
  dev: 'https://stage-www.winkbooks.co.kr/rental/on',
  prod: 'https://www.winkbooks.co.kr/rental/on',
};

export const ITEM_TYPE_NAME = Object.freeze({
  STUDENT_DEVICE: 'StudentDevice',
  STUDENT_DEVICE_ACCESSORY: 'StudentDeviceAccessory',
  WINK_PHONE: 'WinkPhone',
  EDUCATION_MATERIAL: 'EducationMaterial',
  PAID_PRODUCT: 'PaidProduct',
  COUPON: 'Coupon',
  REGULAR_SERVICE_FIRST_GIFT: 'RegularServiceFirstGift',
  STUDENT_ETC_ACCESSORY: 'StudentEtcAccessory',
  PERIODIC_GIFT: 'PeriodicGift',
  ADDITIONAL_PAID_PRODUCT: 'AdditionalPaidProduct',
  SUB_EDUCATION_MATERIAL: 'SubEducationMaterial',
  REAPPLICATION_GIFT: 'ReapplicationGift',
  SUBSCRIPTION_PRODUCT: 'SubscriptionProduct',
  RENTAL_PRODUCT: 'RentalProduct',
  ARS_CHANGE_CREDIT_CARD_PRODUCT: 'ArsChangeCreditCardProduct',
  EDUCATION_MATERIAL_VISUAL_MARKER: 'EducationMaterialVisualMarker',
  HEART_STICKER: 'HeartSticker',
  SUN_STICKER: 'SunSticker',
  EARTH_STICKER: 'EarthSticker',
  RAIN_STICKER: 'RainSticker',
  RESOURCE: 'Resource',
  CONTENT: 'Content',
  WINK_BOOK: 'WinkBook',
  CATS_PRODUCT: 'CatsProduct',
});

export const BANNER_SHOW_PAGE = [
  '/subject',
  '/wink',
  '/reference-room',
  '/customer',
];

const values = {
  DATE_FLAG,
  baseName,
  format,
  boardType,
  boardName,
  BANK_CODE,
  ITEM_TYPE_NAME,
  winkBooksUrl,
  BANNER_SHOW_PAGE,
};

export default values;
