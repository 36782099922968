import {
  BASE_URL,
  HOST,
  HOST_BY_ENV,
  REDIRECT_LEGACY_PAGE_NAME,
} from '@/constants/common';
import { routes } from '@/constants/routes';
import { TQueryValue } from '@/types/common';

export function generateQuery(
  query?: Record<string, TQueryValue | TQueryValue[]>
) {
  if (!query || query === null) {
    return {};
  }
  let queryString = '';
  for (let key of Object.keys(query)) {
    if (Object.prototype.hasOwnProperty.call(query, key)) {
      if (
        query[key] !== undefined &&
        query[key] !== null &&
        query[key] !== ''
      ) {
        if (Array.isArray(query[key])) {
          const arrQuery = query[key] as TQueryValue[];
          if (arrQuery?.length > 0) {
            if (queryString !== '') {
              queryString += '&';
            }
            queryString = queryString.concat(key, '=' + arrQuery);
          }
        } else {
          if (queryString !== '') {
            queryString += '&';
          }
          queryString = queryString.concat(key, '=' + query[key]);
        }
      }
    }
  }

  if (queryString) {
    return '?'.concat(queryString);
  }

  return '';
}

export const getPageName = (
  router: { asPath: string },
  option?: { noQueries?: boolean }
) => {
  const pageName = router.asPath.replace(BASE_URL, '');
  if (option?.noQueries) {
    const idxQuestion = router.asPath.indexOf('?');

    if (idxQuestion === -1) {
      return pageName;
    }

    return pageName.slice(0, idxQuestion);
  }

  return pageName;
};

export const serializeCookie = (cookie?: string) => {
  const cookies = cookie ?? '';
  const cookieArray = cookies.split(';').map((cookie) => cookie.trim());

  const serializedCookies: Record<string, any> = {};
  cookieArray.forEach((cookie) => {
    const [key, value] = cookie.split('=');
    serializedCookies[key] = value;
  });

  return serializedCookies;
};

export const checkHasSessionIdInCookie = (cookie?: string) => {
  const serializedCookies = serializeCookie(cookie);

  return !!serializedCookies.sessionid;
};

/**
 * 마윤트 시점에 query 가 빈객체인 경우 사용
 * @param router
 */
export const getQueryObjFromRouter = (router: { asPath: string }) => {
  const idxQuestion = router.asPath.indexOf('?');

  if (idxQuestion === -1) {
    return {};
  }

  const queryString = router.asPath.slice(idxQuestion).replace('?', '');
  const queries = queryString.split('&');
  const queryObj = {} as Record<string, string | number>;

  queries.forEach((query) => {
    const [key, value] = query.split('=');
    queryObj[key] = decodeURIComponentPlus(value);
  });
  return queryObj;
};

export function decodeURIComponentPlus(str?: string) {
  return decodeURIComponent(str?.replace(/\+/g, ' ') ?? '');
}

export const getRedirectPageForLegacy = (search?: Record<string, string>) => {
  if (!search?.pagename) {
    return null;
  }

  const pageName = REDIRECT_LEGACY_PAGE_NAME[search.pagename] ?? '';
  const searchWithoutPageName = {
    ...search,
  };
  delete searchWithoutPageName.pagename;
  let redirectUrl = routes.home;

  switch (pageName) {
    case '':
      break;
    case routes.customer.myReport:
    case routes.customer.event:
      if (search?.postid) {
        delete searchWithoutPageName.postid;
        redirectUrl = `${pageName}/${search?.postid}${generateQuery(
          searchWithoutPageName
        )}`;
      }
      break;
    case routes.myPage.paymentHistory:
    case routes.myPage.payDetailSingle:
      if (search?.contractid) {
        delete searchWithoutPageName.contractid;
        redirectUrl = `${pageName}/${search?.contractid}${generateQuery(
          searchWithoutPageName
        )}`;
      }
      break;
    case routes.myPage.shippingHistory:
      if (search?.deliveryid) {
        delete searchWithoutPageName.deliveryid;
        redirectUrl = `${pageName}/${search?.deliveryid}${generateQuery(
          searchWithoutPageName
        )}`;
      }
      break;
    default:
      redirectUrl = `${pageName}${generateQuery(searchWithoutPageName)}`;
      break;
  }

  return encodeURI(redirectUrl);
};

export const getHost = () => {
  if (typeof window !== 'undefined') {
    return window.location.hostname;
  }

  return process.env.NEXT_PUBLIC_HOST ?? 'client.local.danbi';
};

export const checkSameEnvOrigin = (
  clientOrigin: string,
  serverOrigin: string
) => {
  if (HOST_BY_ENV.client.local.test(clientOrigin)) {
    return true;
  }

  return Object.values(HOST_BY_ENV.server).some(
    (env: RegExp) => env.test(clientOrigin) && env.test(serverOrigin)
  );
};

export const isProduction = () => {
  return HOST_BY_ENV.server.prod.test(HOST);
};
