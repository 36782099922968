import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StickyContainer } from 'react-sticky';
import { setUser, ErrorBoundary } from '@sentry/nextjs';
import dynamic from 'next/dynamic';

import { LayerPopup } from '../library/layerpopup';
import ExceptionContainer from '@/features/error/ExceptionContainer';
import Header from '@/features/common/components/layout/Header';
import Footer from '@/features/common/components/layout/Footer';

const MemberAutoLogin = dynamic(
  () => import('@/features/member/MemberAutoLogin'),
  {
    ssr: false,
  }
);
const SpinContainer = dynamic(
  () => import('@/features/common/components/SpinContainer'),
  {
    ssr: false,
  }
);
import SpinController from '../library/spin/SpinController';
import LoginInjector from '@/features/common/LoginInjector';
import * as MemberAction from '@/features/member/MemberAction';
import DesktopLayerPopup from '@/features/common/DesktopLayerPopup';
import MemberIdIssuePop from '@/features/member/components/MemberIdIssuePop';

import AceCounter from '../library/AceCounter';
import UserInfo from '@/features/common/components/layout/UserInfo';
import { getHost, getPageName } from '@/utils/miscUtils';
import { routes } from '@/constants/routes';
import ErrorFallBack from '@/features/error/ErrorFallBack';
import MainContentsTop from '@/features/main/components/MainContentsTop';
import { service } from '@/features/common/configs';
import TopButton from '@/features/common/components/layout/TopButton';
import FreeStudyBanner from '@/features/common/components/layout/FreeStudyBanner';

class PageContainer extends Component {
  constructor(props) {
    super(props);

    this.time = 1000 * 60 * 10;
    this.state = {
      reloadTime: parseInt(Date.now() / this.time, 10),
      topBannerHeight: 0,
    };
  }

  setPL(retryCount = 0) {
    const pagename = getPageName(this.props.history);

    if (process.browser && pagename && pagename !== '') {
      const url = window.location.href;

      AceCounter.DesktopPL(url)
        .then(() => {
          console.log('Desktop_PL function call success. url: ', url);
        })
        .catch(() => {
          console.log('---_PL EXCEPTION -- RETRY');
          if (retryCount === 0) {
            setTimeout(() => {
              this.setPL(retryCount + 1);
            }, 500);
          }
        });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { userInfo } = this.props;
    if (prevProps.history.search !== this.props.history.search) {
      SpinController.showSpin();
      if (!this.props.history.query.fixed && process.browser) {
        window.scrollTo(0, 0);
        LayerPopup.clear();
      }
    }

    if (document.body.querySelector('#_lguplus_popup_')) {
      document.body.querySelector('#_lguplus_popup_').remove();
    }

    const pagename = getPageName(this.props.history);
    const prevPagename = getPageName(prevProps.history);

    if (window && prevPagename && pagename !== prevPagename) {
      // this.setPL();

      if (
        this.props.loginProcess === false &&
        prevProps.loginProcess === false
      ) {
        const time = parseInt(Date.now() / this.time, 10);
        if (this.state.reloadTime < time) {
          if (pagename.indexOf('wink/') === -1) {
            window.location.reload();
          } else {
            if (pagename.indexOf('subject/kor') !== -1) {
              window.location.reload();
            }
          }
        }
      }
    }

    if (
      userInfo.isLogin === true &&
      userInfo.userName.indexOf('@noid') !== -1 &&
      this.props.noIdPopupFlag === false
    ) {
      DesktopLayerPopup.modal(<MemberIdIssuePop />);
      const noIdPopupFlag = true;
      this.props.setNoIdPopupFlag(noIdPopupFlag);
    }

    if (
      'client.local.danbi' !== getHost() &&
      userInfo.authId &&
      prevProps.userInfo.authId !== userInfo.authId
    ) {
      setUser({
        id: userInfo.authId,
        username: userInfo.humanName,
      });
    }
  }

  setBannerHeight = (height) => {
    this.setState({ topBannerHeight: height });
  };

  componentDidMount() {
    SpinController.showSpin();
    // this.setPL();
    service.showProjectVersion();
  }

  render() {
    const { topBannerHeight } = this.state;
    const pagename = getPageName(this.props.history);
    const isMain = pagename === routes.home;

    return (
      <StickyContainer>
        <ErrorBoundary fallback={<ErrorFallBack />}>
          <div>
            <div id="wrap">
              <MainContentsTop
                {...this.props}
                pagename={pagename}
                setBannerHeight={this.setBannerHeight}
              />
              <FreeStudyBanner {...this.props} />
              <UserInfo {...this.props} />
              <Header {...this.props} topBannerHeight={topBannerHeight} />
              <div>
                {isMain ? (
                  this.props.children
                ) : (
                  <div id="container">{this.props.children}</div>
                )}
              </div>
              <Footer {...this.props} />
              <TopButton />
            </div>
            <ExceptionContainer {...this.props} />
            <MemberAutoLogin {...this.props} />
            <SpinContainer />
          </div>
        </ErrorBoundary>
      </StickyContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    noIdPopupFlag: state.MemberReducer.noIdPopupFlag,
    loginProcess: state.MemberReducer.loginProcess,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setNoIdPopupFlag: (noIdPopupFlag) => {
      dispatch(MemberAction.setNoIdPopupFlag(noIdPopupFlag));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginInjector(PageContainer));
